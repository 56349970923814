import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import './channels'
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import { Tooltip, Toast, Popover } from 'bootstrap';
import './packs/scripts.bundle'
import './packs/style.bundle'
import './packs/widgets.bundle'
import zxcvbn from 'zxcvbn';


document.addEventListener('turbolinks:load', () => {

  // Initialize KTScroll and KTToggle if they are defined
  if (typeof KTScroll !== 'undefined') {
    KTScroll.init();
  }
  if (typeof KTToggle !== 'undefined') {
    KTToggle.init();
  }

  // Password Strength Meter
  const passwordField = document.querySelector('#user_password');
  const strengthMeter = document.querySelector('#strength-meter');
  const strengthText = document.querySelector('#password-strength-text');

  if (passwordField) {
    passwordField.addEventListener('input', () => {
      const val = passwordField.value;
      const result = zxcvbn(val);

      strengthMeter.value = result.score;

      let strength = '';
      switch (result.score) {
        case 0:
          strength = 'Weak';
          strengthMeter.style.backgroundColor = 'red';
          break;
        case 1:
          strength = 'Weak';
          strengthMeter.style.backgroundColor = 'orange';
          break;
        case 2:
          strength = 'Moderate';
          strengthMeter.style.backgroundColor = 'yellow';
          break;
        case 3:
          strength = 'Strong';
          strengthMeter.style.backgroundColor = 'lightgreen';
          break;
        case 4:
          strength = 'Very Strong';
          strengthMeter.style.backgroundColor = 'green';
          break;
        default:
          strength = 'Weak';
          strengthMeter.style.backgroundColor = 'red';
      }

      strengthText.textContent = strength;
    });
  }

 // Form Validation
  // const form = document.getElementById('myForm');
  // const submitButton = document.getElementById('submit-button');

  // function validateForm() {
  //   let hasErrors = false;

  //   // Fields to validate
  //   const fields = [
  //     { id: 'user_full_name', errorId: 'full_name-error' },
  //     { id: 'user_email', errorId: 'email-error' },
  //     { id: 'user_phone_number', errorId: 'phone_number-error' },
  //     { id: 'user_password', errorId: 'password-error' },
  //     { id: 'user_password_confirmation', errorId: 'password_confirmation-error' },
  //     { id: 'user_game', errorId: 'game-error' },
  //     { id: 'user_country', errorId: 'country-error' },
  //     { id: 'user_birthday', errorId: 'birthday-error' },
  //   ];

  //   fields.forEach(field => {
  //     const input = document.getElementById(field.id);
  //     const errorElement = document.getElementById(field.errorId);

  //     if (input && !input.value.trim()) {
  //       // Create a container div for error message and icon
  //       const errorContainer = document.createElement('div');
  //       errorContainer.classList.add('error-container');
        
  //       // Create the SVG icon
  //       const svgIcon = document.createElement('img');
  //       svgIcon.src = '/assets/icons/inform-icon.svg';
  //       svgIcon.alt = 'inform icon';
  //       svgIcon.classList.add('h-13px', 'me-1');

  //       // Create the error message element
  //       const errorMessage = document.createElement('span');
  //       errorMessage.textContent = 'Required field';

  //       // Append SVG icon and error message to the container
  //       errorContainer.appendChild(svgIcon);
  //       errorContainer.appendChild(errorMessage);

  //       // Clear existing error messages and append new container
  //       while (errorElement.firstChild) {
  //         errorElement.removeChild(errorElement.firstChild);
  //       }
  //       errorElement.appendChild(errorContainer);

  //       // Display the error message
  //       errorElement.style.display = 'block';

  //       // Add error-input class to highlight the input
  //       input.classList.add('error-input');

  //       hasErrors = true;
  //     } else {
  //       // Remove any existing error messages or icons
  //       while (errorElement.firstChild) {
  //         errorElement.removeChild(errorElement.firstChild);
  //       }

  //       // Hide the error message
  //       errorElement.style.display = 'none';

  //       // Remove error-input class
  //       input.classList.remove('error-input');
  //     }
  //   });

  //   submitButton.disabled = hasErrors;
  //   return !hasErrors; 
  // }

  // function toggleSubmitButton() {
  //   // Enable the submit button if the form is valid, otherwise disable it
  //   submitButton.disabled = !validateForm();
  // }

  // if (form) {
  //   form.addEventListener('change', toggleSubmitButton);
  //   form.addEventListener('keyup', toggleSubmitButton);
  //   form.addEventListener('input', toggleSubmitButton);

  //   form.addEventListener('submit', function(event) {
  //     if (validateForm()) {
  //       form.submit();
  //     } else {
  //       event.preventDefault();
  //     }
  //   });
  // }
});

// Initialize Turbolinks, Rails UJS, and Active Storage
Rails.start()
Turbolinks.start()
ActiveStorage.start()

